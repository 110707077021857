@import "../../../../../../../design/frontend/Stonewave/base-theme/web/sass/variables";
@import "../../../../../../../design/frontend/Stonewave/base-theme/node_modules/bootstrap/scss/mixins/breakpoints";

.promo-button{
    min-height: 40px !important;
    font-size: 16px !important;
    line-height: 18px !important;
    font-weight: $sw-normal !important;
    max-width: 100%;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
        text-decoration: none;
    }

    > * {
        vertical-align: middle;
    }
}

.promo-messages-row {
    min-height: 20px;
    letter-spacing: .1em;
    text-align: center;
    font-size: 12px;
    font-weight: $sw-medium;
    @include media-breakpoint-up(lg) {
        min-height: 30px;
        font-size: 16px;
    }
}

.promo-message > *:not(:last-child){
    margin-right: 30px;
}

.promo-link{
    text-decoration: underline;
}

.promo-message .counter{
    font-size: 16px;
    opacity: 0.5;
    padding: 4px 3px 4px 4px;
    border-radius: 3px;
}