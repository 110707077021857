/* Fonts */
//$font-family-ubuntu:    "Ubuntu", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
// $font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
// $font-family-base: $font-family-sans-serif;

$font-family-roboto: 'Roboto', sans-serif;
$font-family-roboto-condensed: 'Roboto Condensed', sans-serif;
$font-family-base: $font-family-roboto;

/* Colors */

$white: #fff;
$black: #000;

// $main-color: $black;
// $secondary-color: #c8c8c8;

$badge-color-sale: #ff0000;
$badge-backgroundcolor-sale: #008000;
$badge-color-new: #ffA500;
$badge-backgroundcolor-new: #0000FF;

/* Font Weights */
$sw-thin: 100;
$sw-extra-light: 200;
$sw-light: 300;
$sw-normal: 400;
$sw-medium: 500;
$sw-semibold: 600;
$sw-bold: 700;
$sw-extra-bold: 800;
$sw-ultra-bolder: 900;  
$sw-lighter: lighter;
$sw-bolder: bolder;

/* Spaces */
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
        (
            0: 0, //0px
            1: ($spacer * 0.3125),  //5px
            2: ($spacer * 0.625),   //10px
            3: ($spacer * 0.9375),  //15px
            4: $spacer,             //16px
            5: ($spacer * 1.25), //20px
            6: ($spacer * 1.5625), //25px
            7: ($spacer * 1.875), //30px
            8: ($spacer * 2.1875), //35px
            9: ($spacer * 2.5), //40px
            10: ($spacer * 2.8125), //45px
            11: ($spacer * 3.125), //50px
            12: ($spacer * 3.4375), //55px
            13: ($spacer * 3.75), //60px
            14: ($spacer * 4.0625), //65px
            15: ($spacer * 4.375), //70px
            16: ($spacer * 4.6875), //75px
            17: ($spacer * 5), //80px
            18: ($spacer * 5.3125), //85px
            19: ($spacer * 5.625), //90px
            20: ($spacer * 5.9375), //95px
            21: ($spacer * 6.25), //100px
            22: ($spacer * 6.5625), //105px
            23: ($spacer * 6.875), //110px
            24: ($spacer * 6.7 .1875), //115px
            25: ($spacer * 7.5), //120px
        ),
        $spacers
);

/* Sizes */
$sizes: (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%
);

/* Breakpoints */
$grid-breakpoints: (
    'xxs': 0px,
    'xs': 375px,
    'sm': 576px,
    'md': 768px,
    'lg': 1024px,
    'xl': 1200px,
    'xxl': 1440px,
);

$breakpoints: (
    'xxs': 320px,
    'xs': 375px,
    'sm': 576px,
    'md': 768px,
    'lg': 1024px,
    'xl': 1200px,
    'xxl': 1440px,
);

/* Container Width */
$container-max-widths: (
    xxl: 1440px,
);

$grid-gutter-width: 30px !default;
$paragraph-margin-bottom: 0 !default;

$fa-font-path: "../fonts";
